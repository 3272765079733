import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import WeeklyDate from './WeeklyDate';
import { vAPIURL, vGlobalDateNow, CheckScreenWidth } from '../../Global';
import axios from 'axios';
import toastr from 'toastr';
import format from 'date-fns/format';

ChartJS.register(...registerables )

const ChartReservation = () => {

	const [Reservation, SetReservation] = useState(['']);
	const [loading, setLoading] = useState(false)

	let data = {
		labels: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
		datasets: [{
			label: 'LAST WEEK',
			data: Reservation.map(row => row.TotalPlayer).slice(0, 7),
			backgroundColor: Reservation.map(row => row.Color).slice(0, 7),
			borderColor: Reservation.map(row => row.Color).slice(0, 7),
			borderWidth: 1 
		} , {
			label: 'THIS WEEK',
			data: Reservation.map(row => row.TotalPlayer).slice(7, 14),
			backgroundColor: Reservation.map(row => row.Color).slice(7, 14),
			borderColor: Reservation.map(row => row.Color).slice(7, 14),
			borderWidth: 1
		} , {
			label: 'NEXT WEEK',
			data: Reservation.map(row => row.TotalPlayer).slice(14,21),
			backgroundColor: Reservation.map(row => row.Color).slice(14, 21),
			borderColor: Reservation.map(row => row.Color).slice(14, 21),
			borderWidth: 1
		}]
	};

	let option = {
		maintainAspecRatio: true,
		responsive : true,
		scales: {
			x: {
				beginAtZero: true
			},
			y: {
				beginAtZero: true
			}
		},
		plugins: {
			legend: {
				display: true,
				position: 'top',
				align: 'center',
				labels: {
					boxHeight: 5,
					boxWidth: 10,
					textAlign: 'left'
				}
			},
			tooltip: {
				callbacks: {
					label: ((tooltipItem, data) => {
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 0) {
							return Reservation.map(row => row.Date).slice(0, 1);
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 1) {
							return Reservation.map(row => row.Date).slice(1, 2);
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 2) {
							return Reservation.map(row => row.Date).slice(2, 3);
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 3) {
							return Reservation.map(row => row.Date).slice(3, 4);
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 4) {
							return Reservation.map(row => row.Date).slice(4, 5);
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 5) {
							return Reservation.map(row => row.Date).slice(5, 6);
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 6) {
							return Reservation.map(row => row.Date).slice(6, 7);
						}
						if (tooltipItem.datasetIndex === 1 && tooltipItem.dataIndex === 0) {
							return Reservation.map(row => row.Date).slice(7, 8);
						}
						if (tooltipItem.datasetIndex === 1 && tooltipItem.dataIndex === 1) {
							return Reservation.map(row => row.Date).slice(8, 9);
						}
						if (tooltipItem.datasetIndex === 1 && tooltipItem.dataIndex === 2) {
							return Reservation.map(row => row.Date).slice(9, 10);
						}
						if (tooltipItem.datasetIndex === 1 && tooltipItem.dataIndex === 3) {
							return Reservation.map(row => row.Date).slice(10, 11);
						}
						if (tooltipItem.datasetIndex === 1 && tooltipItem.dataIndex === 4) {
							return Reservation.map(row => row.Date).slice(11, 12);
						}
						if (tooltipItem.datasetIndex === 1 && tooltipItem.dataIndex === 5) {
							return Reservation.map(row => row.Date).slice(12, 13);
						}
						if (tooltipItem.datasetIndex === 1 && tooltipItem.dataIndex === 6) {
							return Reservation.map(row => row.Date).slice(13, 14);
						}
						if (tooltipItem.datasetIndex === 2 && tooltipItem.dataIndex === 0) {
							return Reservation.map(row => row.Date).slice(14, 15);
						}
						if (tooltipItem.datasetIndex === 2 && tooltipItem.dataIndex === 1) {
							return Reservation.map(row => row.Date).slice(15, 16);
						}
						if (tooltipItem.datasetIndex === 2 && tooltipItem.dataIndex === 2) {
							return Reservation.map(row => row.Date).slice(16, 17);
						}
						if (tooltipItem.datasetIndex === 2 && tooltipItem.dataIndex === 3) {
							return Reservation.map(row => row.Date).slice(17, 18);
						}
						if (tooltipItem.datasetIndex === 2 && tooltipItem.dataIndex === 4) {
							return Reservation.map(row => row.Date).slice(18, 19);
						}
						if (tooltipItem.datasetIndex === 2 && tooltipItem.dataIndex === 5) {
							return Reservation.map(row => row.Date).slice(19, 20);
						}
						if (tooltipItem.datasetIndex === 2 && tooltipItem.dataIndex === 6) {
							return Reservation.map(row => row.Date).slice(20, 21);
						}
					}
					),
					title: function(tooltipItem, data) {
						return '';
					}
				}
			},
			datalabels: {
				anchor: 'center',
				align: 'center',
				display: 'true',
				clamp: true
			},
		}
	}

	const getReservweekly = async () => {
		setLoading(true);
		let vStatus = 1;
		let vMessage = [];

		try{
			const vURL = vAPIURL + "/Golf/Reservation";
			const vData = {
				Action: {
					 Value: "Search",
				},
				Input: {
					Date: {
						Value: format( new Date( vGlobalDateNow ) , 'dd-MMM-yyyy' ),
					},
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},  
			};

			await axios
				.post(vURL, vData, vConfig)
				.then(
					(vResponse) => {
						vStatus = vResponse.data.Status;

						for(var i = 0; i < vResponse.data.Message.length; i++){
							vMessage.push(vResponse.data.Message[ i ]);
						}

						if(vStatus === 1){
							SetReservation(vResponse.data.Output.Result)
						}
					}
				)
				.catch(
					(vError) => {
						vStatus = 2;
						vMessage.push( "Error" );
					}
				);
		}catch( vError ){
			vStatus = 2;
			vMessage.push( "Error" );
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
				//toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
		setLoading(false)
	}

	useEffect(() => {
		getReservweekly()
	}, [])

	return (
		<>
			{
				CheckScreenWidth ?
				<div className="card bg-gradient-light">
					<div className="card-header">
						<ul className="nav nav-pills ml-auto">
							<li className="nav-item text-info font-weight-bold">
								WEEKLY RESERVATION CHART
							</li>
						</ul>
						<ul className="nav nav-pills ml-auto">
							<li className="nav-item text-info">
								(In Qty)
							</li>
						</ul>
						<div>
							<WeeklyDate />
						</div>
					</div>
					<div className="card-body"> 
						<div className="tab-content p-0 ">
							{loading ?
								<div className="d-flex justify-content-center">
									<i className="fas fa-3x fa-sync-alt fa-spin"></i>
								</div>
								:
								<>
									<div>
										<Bar
											data={data}
											plugins={[ChartDataLabels]}
											options={option}
											height={100}
										/>
									</div>
								</>
							}
						</div>
					</div>
				</div>
				:
				<div className="card bg-gradient-light">
					<div className="card-header">
						<ul className="nav nav-pills ml-auto">
							<li className="nav-item text-info font-weight-bold">
								WEEKLY RESERVATION CHART
							</li>
						</ul>
						<ul className="nav nav-pills ml-auto">
							<li className="nav-item text-info">
								(In Qty)
							</li>
						</ul>
						<div>
							<WeeklyDate />
						</div>
					</div>
					<div className="card-body"> 
						<div className="tab-content p-0 ">
							{loading ?
								<div className="d-flex justify-content-center">
									<i className="fas fa-3x fa-sync-alt fa-spin"></i>
								</div>
								:
								<>
									<div>
										<Bar
											data={data}
											plugins={[ChartDataLabels]}
											options={option}
											height={300}
										/>
									</div>
								</>
							}
						</div>
					</div>
				</div>
			}
		</>
	)
}

export default ChartReservation