import React, { useEffect, useState, Suspense, lazy } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { vAPIURL, vGlobalDateNow, CheckScreenWidth } from '../../Global';
import axios from 'axios';
import toastr from 'toastr';
import format from 'date-fns/format';
import Loading from '../Revenue/LazyLoading'; 
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { startOfWeek, endOfWeek, startOfMonth, endOfMonth, endOfYear, startOfYear } from 'date-fns';

ChartJS.register(...registerables )

const BookingVsCheckinDetail = lazy(() => import('./BookingVsCheckinDetail.js'));

const BookingVsCheckin = ({Period}) => {
	
	const [loading, setLoading] = useState(false)
	const [DataTemp, SetDataTemp] = useState([])
	const [OpenChart, SetOpenChart] = useState(false)
	const [DetailDate, SetDetailDate] = useState("")
	let StartDateTemp = ""
	let EndDateTemp = ""
	if ( Period == "Daily" ){
		EndDateTemp = new Date( vGlobalDateNow )
		StartDateTemp = new Date( EndDateTemp.getTime() - ( 7 * 24 * 60 * 60 * 1000 ) )
	}
	else if ( Period == "Weekly" ){
		EndDateTemp = new Date( vGlobalDateNow );
		let EndDateWeek = EndDateTemp.getDay();
		let AddEndDate = EndDateWeek < 6 ? 6 - EndDateWeek : 0;
		EndDateTemp.setDate(EndDateTemp.getDate() + AddEndDate);

		StartDateTemp = new Date( vGlobalDateNow );
		let SubStartDate = (StartDateTemp.getDay() + 7) % 7 + 7 * 4;
		StartDateTemp.setDate(StartDateTemp.getDate() - SubStartDate);
	}
	else if ( Period == "Monthly" ){
		const NewDate = new Date( vGlobalDateNow );

		EndDateTemp = endOfMonth(NewDate);
		StartDateTemp = subtractMonths(startOfMonth(NewDate), 13);
	}
	else {
		const NewDate = new Date( vGlobalDateNow );

		EndDateTemp = endOfYear(NewDate);
		StartDateTemp = subtractYears(startOfYear(NewDate), 5);
	}
	const [EndDate, SetEndDate] = useState( EndDateTemp );
	const [StartDate, SetStartDate] = useState( StartDateTemp );

	function subtractMonths(date, months) {
		const newDate = new Date( date );
		newDate.setMonth(date.getMonth() - months);
		return newDate;
	}

	function subtractYears(date, years) {
		date.setFullYear(date.getFullYear() - years);
		return date;
	}

	const GetData = async() => {
		setLoading( true );
		let vStatus = 1;
		let vMessage = [];

		try{
			const vURL = vAPIURL + "/Golf/Reservation/BookingVsCheckin";
			const vData = {
				Action: {
					Value: "Search"
				},
				Input: {
					StartDate: {
						Value: format( StartDate, 'dd-MMM-yyyy' ),
					},
					EndDate: {
						Value: format( EndDate, 'dd-MMM-yyyy' ),
					},
					Period: {
						Value: Period,
					},
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};

			await axios
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;

						for( var i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}

						if( vStatus === 1 ){
							SetDataTemp( vResponse.data.Output.Result )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "Error" );
					}
				);
		}catch( vError ){
			vStatus = 2;
			vMessage.push( "Error" );
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
				//toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
		setLoading( false );
	}

	let ChartData = {
		labels: DataTemp.map( Value => Value.Date ),
		datasets: [
			{
				label: 'BOOKING',
				data: DataTemp.map( Value => Value.Booking ),
				backgroundColor: 'rgb(219, 219, 26)',
				borderColor: 'rgb(219, 219, 26)',
				borderWidth: 1
			},
			{
				label: 'CANCEL',
				data: DataTemp.map( Value => Value.Cancel ),
				backgroundColor: 'rgba(235, 64, 52, 0.9)',
				borderColor: 'rgba(235, 64, 52, 0.9)',
				borderWidth: 1
			},
			{
				label: 'NO-SHOW',
				data: DataTemp.map( Value => Value.NoShow ),
				backgroundColor: 'rgba(255, 19, 3, 0.9)',
				borderColor: 'rgba(255, 19, 3, 0.9)',
				borderWidth: 1
			},
			{
				label: 'TOTAL PLAYER',
				data: DataTemp.map( Value => Value.Actual ),
				backgroundColor: 'rgba(0, 0, 255, 0.9)',
				borderColor: 'rgba(0, 0, 255, 0.9)',
				borderWidth: 1
			},
			{
				label: 'CHECK-IN',
				data: DataTemp.map( Value => Value.CheckinViaBooking ),
				backgroundColor: 'rgb(50, 168, 82)',
				borderColor: 'rgb(50, 168, 82)',
				borderWidth: 1,
				stack: 1
			},
			{
				label: 'GO-SHOW',
				data: DataTemp.map( Value => Value.WalkinCheckin ),
				backgroundColor: 'rgb(230, 28, 223)',
				borderColor: 'rgb(230, 28, 223)',
				borderWidth: 1,
				stack: 1
			},
			{
				label: 'AVERAGE ACTUAL',
				data: DataTemp.map( Value => Value.AverageActual ),
				backgroundColor: 'rgba(72, 212, 219, 0.9)',
				borderColor: 'rgba(72, 212, 219, 0.9)',
				type: 'line',
				datalabels: {
					labels: {
					  title: null
					}
				}
			},
		],
	}

	let ChartOption = {
		maintainAspecRatio: true,
		responsive : true,
		scales: {
			x: {
				beginAtZero: true,
			},
			y: {
				beginAtZero: true,
			},
		},
		plugins: {
			legend: {
				display: true,
				position: 'top',
				align: 'center',
				labels: {
					boxHeight: 10,
					boxWidth: 10,
					textAlign: 'left'
				}
			},
			tooltip: {
				callbacks: {
					title: function(tooltipItem, data) {
						return '';
					}
				}
			}
		},
		onClick: (e, activeEls) => {
			let DataIndex = activeEls[0].index
			SetDetailDate(e.chart.data.labels[DataIndex])
			SetOpenChart(true)
		},
		onHover: (e, ChartElement) => {
			e.native.target.style.cursor = ChartElement[0] ? 'pointer' : 'default'
		},
	}

	const renderMonthContent = (month, shortMonth, longMonth) => {
		return <span>{shortMonth}</span>;
	};

	const renderYearContent = (year) => {
		return <span>{year}</span>;
	};

	useEffect(() => {
		GetData()
	},[])

	return (
		<>
			{
				CheckScreenWidth ?
				<div className="col-6">
					<div className="card bg-gradient-light">
						<div className="card-header">
							<h3 className="card-title text-success font-weight-bold">
								BOOKING VS CHECK-IN
							</h3>
							<div className="card-tools">
								<ul className="nav nav-pills ml-auto">
									<li className="nav-item text-info font-weight-bold">
										{Period.toUpperCase()}
									</li>
								</ul>
							</div>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-sm-4">
									<div className="form-group">
										<label>
											START DATE
										</label>
										{
											Period == "Daily" 
												?
												<DatePicker
													dateFormat="dd-MMM-yyyy"
													selected={ StartDate }
													onChange={e => SetStartDate(e)}
													className="form-control"
													value={ StartDate }
												/>
												:
											Period == "Weekly" 
												?
												<DatePicker
													dateFormat="dd-MMM-yyyy"
													selected={StartDate}
													onChange={e => SetStartDate(startOfWeek(e, { weekStartsOn: 0 }))}
													className="form-control"
													value={StartDate}
												/>
												:
											Period == "Monthly" 
												?
												<DatePicker
													dateFormat="MMM-yyyy"
													renderMonthContent={renderMonthContent}
													showMonthYearPicker
													selected={StartDate}
													onChange={e => SetStartDate(startOfMonth(e))}
													className="form-control"
													value={StartDate}
												/>
												:
												<DatePicker
													dateFormat="yyyy"
													renderYearContent={renderYearContent}
													showYearPicker
													selected={StartDate}
													onChange={e => SetStartDate(startOfYear(e))}
													className="form-control"
													value={StartDate}
												/>
										}
									</div>
								</div>
								<div className="col-sm-4">
									<div className="form-group">
										<label>
											END DATE
										</label>
										{
											Period == "Daily" 
												?
												<DatePicker
													dateFormat="dd-MMM-yyyy"
													selected={ EndDate }
													onChange={e => SetEndDate(e)}
													className="form-control"
													value={ EndDate }
												/>
												:
											Period == "Weekly" 
												?
												<DatePicker
													dateFormat="dd-MMM-yyyy"
													selected={EndDate}
													onChange={e => SetEndDate(endOfWeek(e, { weekStartsOn: 0 }))}
													className="form-control"
													value={EndDate}
												/>
												:
											Period == "Monthly" 
												?
												<DatePicker
													dateFormat="MMM-yyyy"
													renderMonthContent={renderMonthContent}
													showMonthYearPicker
													selected={EndDate}
													onChange={e => SetEndDate(endOfMonth(e))}
													className="form-control"
													value={EndDate}
												/>
												:
												<DatePicker
													dateFormat="yyyy"
													renderYearContent={renderYearContent}
													showYearPicker
													selected={EndDate}
													onChange={e => SetEndDate(endOfYear(e))}
													className="form-control"
													value={EndDate}
												/>
										}
									</div>
								</div>
								<div className="col-sm-4">
									<button
										onClick={GetData}
										type="submit"
										className="btn btn-primary float-right"
										style={{ marginTop:"31px" }}
									>
										<i className="fas fa-search"/>
										&nbsp;
										{loading ?
												"Please wait..."
											:
												"Search"
										}
									</button>
								</div>
							</div>
							<div className="tab-content p-0">
								{
									loading ?
										<div className="d-flex justify-content-center">
											<i className="fas fa-3x fa-sync-alt fa-spin"/>
										</div>
									:
										<div>
											<Bar
												data={ChartData}
												options={ChartOption}
												height={200}
											/>
										</div>
								}
							</div>
						</div>
					</div>
				</div>
				:
				<div className="col-12">
					<div className="card bg-gradient-light">
						<div className="card-header">
							<h3 className="card-title text-success font-weight-bold">
								BOOKING VS CHECK-IN
							</h3>
							<div className="card-tools">
								<ul className="nav nav-pills ml-auto">
									<li className="nav-item text-info font-weight-bold">
										{Period.toUpperCase()}
									</li>
								</ul>
							</div>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-12">
									<label>
										START DATE
									</label>
								</div>
								<div className="col-6">
									{
										Period == "Daily" 
											?
											<DatePicker
												dateFormat="dd-MMM-yyyy"
												selected={ StartDate }
												onChange={e => SetStartDate(e)}
												className="form-control"
												value={ StartDate }
											/>
											:
										Period == "Weekly" 
											?
											<DatePicker
												dateFormat="dd-MMM-yyyy"
												selected={StartDate}
												onChange={e => SetStartDate(startOfWeek(e, { weekStartsOn: 0 }))}
												className="form-control"
												value={StartDate}
											/>
											:
										Period == "Monthly" 
											?
											<DatePicker
												dateFormat="MMM-yyyy"
												renderMonthContent={renderMonthContent}
												showMonthYearPicker
												selected={StartDate}
												onChange={e => SetStartDate(startOfMonth(e))}
												className="form-control"
												value={StartDate}
											/>
											:
											<DatePicker
												dateFormat="yyyy"
												renderYearContent={renderYearContent}
												showYearPicker
												selected={StartDate}
												onChange={e => SetStartDate(startOfYear(e))}
												className="form-control"
												value={StartDate}
											/>
									}
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<label>
										END DATE
									</label>
								</div>
								<div className="col-6">
									{
										Period == "Daily" 
											?
											<DatePicker
												dateFormat="dd-MMM-yyyy"
												selected={ EndDate }
												onChange={e => SetEndDate(e)}
												className="form-control"
												value={ EndDate }
											/>
											:
										Period == "Weekly" 
											?
											<DatePicker
												dateFormat="dd-MMM-yyyy"
												selected={EndDate}
												onChange={e => SetEndDate(endOfWeek(e, { weekStartsOn: 0 }))}
												className="form-control"
												value={EndDate}
											/>
											:
										Period == "Monthly" 
											?
											<DatePicker
												dateFormat="MMM-yyyy"
												renderMonthContent={renderMonthContent}
												showMonthYearPicker
												selected={EndDate}
												onChange={e => SetEndDate(endOfMonth(e))}
												className="form-control"
												value={EndDate}
											/>
											:
											<DatePicker
												dateFormat="yyyy"
												renderYearContent={renderYearContent}
												showYearPicker
												selected={EndDate}
												onChange={e => SetEndDate(endOfYear(e))}
												className="form-control"
												value={EndDate}
											/>
									}
								</div>
								<div className="col-6">
									<button
										onClick={GetData}
										type="submit"
										className="btn btn-primary float-right"
									>
										<i className="fas fa-search"/>
										&nbsp;
										{loading ?
												"Please wait..."
											:
												"Search"
										}
									</button>
								</div>
							</div>
							<div className="tab-content p-0">
								{
									loading ?
										<div className="d-flex justify-content-center">
											<i className="fas fa-3x fa-sync-alt fa-spin"/>
										</div>
									:
										<div>
											<Bar
												data={ChartData}
												options={ChartOption}
												height={300}
											/>
										</div>
								}
							</div>
						</div>
					</div>
				</div>
			}
			{OpenChart ? (
				<Suspense fallback={<Loading />}>
					<BookingVsCheckinDetail
						Period={Period}
						DetailDate={DetailDate}
					/>
				</Suspense>
				) : ""
			}
		</>
	)
}

export default BookingVsCheckin