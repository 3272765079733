import React, { useEffect, useState } from 'react'
import YearlyDate from './YearlyDate';
import { vAPIURL } from '../../Global';
import axios from 'axios';
import toastr from 'toastr';
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

const TableCCHYearly = () => {

	const [TotalQtyYearly, SetTotalQtyYearly] = useState([]);
	const [loading, setLoading] = useState(false)

	const GetTotalQtyYearly = async () => {
		setLoading(true);
		let vStatus = 1;
		let vMessage = [];

		try{
			const vURL = vAPIURL + "/Golf/Reservation/RoomYearly";
			const vData = {
				Action: {
					Value: "Search",
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};
			
			await axios
				.post(vURL, vData, vConfig)
				.then(
					(vResponse) => {
						vStatus = vResponse.data.Status;

						for(var i = 0; i < vResponse.data.Message.length; i++){
							vMessage.push(vResponse.data.Message[i]);
						}

						if(vStatus === 1){
							SetTotalQtyYearly(vResponse.data.Output.Result)
						}
					}
				)
				.catch(
					(vError) => {
						vStatus = 2;
						vMessage.push("Error");
					}
				);
		}catch(vError){
			vStatus = 2;
			vMessage.push("Error");
		}

		if(vMessage.length > 0){
			if(vStatus === 1){
				//toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
		setLoading(false)
	}

	useEffect(() => {
		GetTotalQtyYearly()
	}, [])  

	let CCHA = TotalQtyYearly.map(row => row.CCHA)

	let SumCCHA = 0
	CCHA.forEach(Value => {
		SumCCHA += parseInt(Value);
	});
	CCHA.push(SumCCHA);

	let CCHB = TotalQtyYearly.map(row => row.CCHB)

	let SumCCHB = 0
	CCHB.forEach(Value => {
		SumCCHB += parseInt(Value);
	});
	CCHB.push(SumCCHB);

	let CCHC = TotalQtyYearly.map(row => row.CCHC)

	let SumCCHC = 0
	CCHC.forEach(Value => {
		SumCCHC += parseInt(Value);
	});
	CCHC.push(SumCCHC);

	let CCHD = TotalQtyYearly.map(row => row.CCHD)

	let SumCCHD = 0
	CCHD.forEach(Value => {
		SumCCHD += parseInt(Value);
	});
	CCHD.push(SumCCHD);
	
	let CCHE = TotalQtyYearly.map(row => row.CCHE)

	let SumCCHE = 0
	CCHE.forEach(Value => {
		SumCCHE += parseInt(Value);
	});
	CCHE.push(SumCCHE);

	let CCHM = TotalQtyYearly.map(row => row.CCHM)

	let SumCCHM = 0
	CCHM.forEach(Value => {
		SumCCHM += parseInt(Value);
	});
	CCHM.push(SumCCHM);

	let CCHQ = TotalQtyYearly.map(row => row.CCHQ)
	
	let SumCCHQ = 0
	CCHQ.forEach(Value => {
		SumCCHQ += parseInt(Value);
	});
	CCHQ.push(SumCCHQ);

	let CCHO = TotalQtyYearly.map(row => row.CCHO)
	
	let SumCCHO = 0
	CCHO.forEach(Value => {
		SumCCHO += parseInt(Value);
	});
	CCHO.push(SumCCHO);

	const HeaderTable = [ "JAN", 'FEB', "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC", "TOTAL" ]

	return (
		<div className="card bg-gradient-light">
			<div className="card-header">
				<ul className="nav nav-pills ml-auto">
					<li className="nav-item text-info font-weight-bold">
						CCH USAGE TABLE YEAR TO DATE
					</li>
				</ul>
				<ul className="nav nav-pills ml-auto">
					<li className="nav-item text-info">
						(In Qty)
					</li>
				</ul>
				<div>
					<YearlyDate />
				</div>
			</div>
			<div className="card-body">
				<div className="tab-content p-0 ">
					{loading ?
						<div className="d-flex justify-content-center">
							<i className="fas fa-3x fa-sync-alt fa-spin"></i>
						</div>
						:
						<>
							<div>
							<TableContainer>
									<Table aria-label="simple table">
										<TableHead>
											<TableCell sx={{ border: 1, width: 200, fontWeight: 'bold', padding: 0.5 }}>ROOM</TableCell>
											{HeaderTable.map((Value) => (
												<TableCell sx={{ border: 1, width: 100, fontWeight: 'bold', padding: 0.5 }} align='center'>{Value}</TableCell>
											))}
										</TableHead>
										<TableHead>
											<TableCell sx={{ border: 1, width: 200, fontWeight: 'bold', padding: 0.5 }}>CCH A</TableCell>
											{CCHA.map((Value, index) => (
												<TableCell 
													sx={{ 
														border: 1
														, width: 100
														, padding: 0.5
														, fontWeight:(index === 12 ? 'bold' : '')
													}}
													align={index === 12 ? 'right' : 'center'}
												>
													{Value}
												</TableCell>
											))}
										</TableHead>
										<TableHead>
											<TableCell sx={{ border: 1, width: 200, fontWeight: 'bold', padding: 0.5 }} align="left" variant="head">CCH B</TableCell>
											{CCHB.map((Value, index) => (
												<TableCell 
													sx={{ 
														border: 1
														, width: 100
														, padding: 0.5
														, fontWeight:(index === 12 ? 'bold' : '')
													}}
													align={index === 12 ? 'right' : 'center'}
												>
													{Value}
												</TableCell>
											))}
										</TableHead>
										<TableHead>
											<TableCell sx={{ border: 1, width: 200, fontWeight: 'bold', padding: 0.5}} align="left" variant="head">CCH C</TableCell>
											{CCHC.map((Value, index) => (
												<TableCell 
													sx={{ 
														border: 1
														, width: 100
														, padding: 0.5
														, fontWeight:(index === 12 ? 'bold' : '')
													}}
													align={index === 12 ? 'right' : 'center'}
												>
													{Value}
												</TableCell>
											))}
										</TableHead>
										<TableHead>
											<TableCell sx={{ border: 1, width: 200, fontWeight: 'bold', padding: 0.5 }} align="left" variant="head">CCH D</TableCell>
											{CCHD.map((Value, index) => (
												<TableCell 
													sx={{ 
														border: 1
														, width: 100
														, padding: 0.5
														, fontWeight:(index === 12 ? 'bold' : '')
													}}
													align={index === 12 ? 'right' : 'center'}
												>
													{Value}
												</TableCell>
											))}
										</TableHead>
										<TableHead>
											<TableCell sx={{ border: 1, width: 200, fontWeight: 'bold', padding: 0.5 }} align="left" variant="head">CCH E</TableCell>
											{CCHE.map((Value, index) => (
												<TableCell 
													sx={{ 
														border: 1
														, width: 100
														, padding: 0.5
														, fontWeight:(index === 12 ? 'bold' : '')
													}}
													align={index === 12 ? 'right' : 'center'}
												>
													{Value}
												</TableCell>
											))}
										</TableHead>
										<TableHead>
											<TableCell sx={{ border: 1, width: 200, fontWeight: 'bold', padding: 0.5 }} align="left" variant="head">CCH M</TableCell>
											{CCHM.map((Value, index) => (
												<TableCell 
													sx={{ 
														border: 1
														, width: 100
														, padding: 0.5
														, fontWeight:(index === 12 ? 'bold' : '')
													}}
													align={index === 12 ? 'right' : 'center'}
												>
													{Value}
												</TableCell>
											))}
										</TableHead>
										<TableHead>
											<TableCell sx={{ border: 1, width: 200, fontWeight: 'bold', padding: 0.5 }} align="left" variant="head">CCH O</TableCell>
											{CCHO.map((Value, index) => (
												<TableCell 
													sx={{ 
														border: 1
														, width: 100
														, padding: 0.5
														, fontWeight:(index === 12 ? 'bold' : '')
													}}
													align={index === 12 ? 'right' : 'center'}
												>
													{Value}
												</TableCell>
											))}
										</TableHead>
										<TableHead>
											<TableCell sx={{ border: 1, width: 200, fontWeight: 'bold', padding: 0.5 }} align="left" variant="head">CCH Q</TableCell>
											{CCHQ.map((Value, index) => (
												<TableCell 
													sx={{ 
														border: 1
														, width: 100
														, padding: 0.5
														, fontWeight:(index === 12 ? 'bold' : '')
													}}
													align={index === 12 ? 'right' : 'center'}
												>
													{Value}
												</TableCell>
											))}
										</TableHead>
									</Table>
								</TableContainer>
							</div>
						</>
					}
				</div>
			</div>
		</div>
	)
}

export default TableCCHYearly