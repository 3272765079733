import React, { useEffect,useState } from "react"
import ExecutiveHighlight from "./Module/ExecutiveHighlight"
import NumberOfPlayer from "./Module/NumberOfPlayer"
import GolfOperation from "./Module/GolfOperations"
import Reservation from "./Module/Reservation"
import FnBService from "./Module/Fnbservices"
import FnBProduction from "./Module/Fnbproduction"
import ForgotPassword from "./Module/ForgotPassword"
import ForgotPasswordC from "./Module/ForgotPasswordC"
import PasswordChange from "./Module/PasswordChange"
import SignOTP from "./Module/SignOTP"
import SignIn from "./Module/SignIn"
import SignOut from "./Module/SignOut"
import SalesManagement from "./Module/SalesManagement"
import IncomeStatement from "./Module/IncomeStatement"
import BalanceSheet from "./Module/BalanceSheet"
import Revenue from "./Module/Revenue"
import { Route, Routes, Navigate } from "react-router-dom"
import { vAPIURL } from "./Global";
import MainPage from "./Module/MainPage"
import Layout from "./Layout"
import axios from "axios";
import toastr from "toastr";
import NotFoundPath from "./Widget/NotFoundPath"
import SettingMenuUser from "./Module/SettingMenuUser"

const Router = () => {
	const [ MenuDataTemp, setMenuDataTemp ] = useState( [] )
	const [ UserRoleTemp, setUserRoleTemp ] = useState( "" )

	let vAuthentication = "";

	if( localStorage.getItem( "Authentication" ) !== null ){
		vAuthentication = localStorage.getItem( "Authentication" );
	}

	const FGenerateMenu = async () => {
		let vStatus = 1
		let vMessage = []

		try{
			const vURL = vAPIURL + "/Golf/Menu/Generate";
			const vData = {
				Action: {
					Value: "Generate"
				},
				Input: {
					Mode: {
						Value: 2
					},
					Application: {
						Value: "DASH"
					},
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};
			await axios
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;

						for( let i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}

						if( vStatus === 1 ){
							setMenuDataTemp( vResponse.data.Output.WithParent )
							setUserRoleTemp( vResponse.data.Rule.Code )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "Error" );
					}
				);
		}catch( vError ){
			vStatus = 2;
			vMessage.push( "Error" );
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
				localStorage.clear();
				window.location.replace("/Sign/In");
			}
		}
	}
	
	function GenerateMenuPath( MenuData, UserRole ){
		let ElementTemp = []
		
		ElementTemp.push(
			<Route key="/Sign/Out" path="/Sign/Out" element={<SignOut/>} />
		)
		ElementTemp.push(
			<Route key="/Sign/OTP" path="/Sign/OTP" element={<SignOTP/>} />
		)
		ElementTemp.push(
			<Route key="/Password/Change" path="/Password/Change" element={<PasswordChange/>} />
		)
		ElementTemp.push(
			<Route key="/" path="/" element={<Layout><MainPage/></Layout>} />
		)

		if( UserRole === "SYSADMIN" ){
			ElementTemp.push(
				<Route key="/SettingMenuUser" path="/SettingMenuUser" element={<Layout><SettingMenuUser/></Layout>} />
			)
		}
		else if ( UserRole === "DASH_ADM" ){
			ElementTemp.push(
				<Route key="/SettingMenuUser" path="/SettingMenuUser" element={<Layout><SettingMenuUser/></Layout>} />
			)
		}
		else{
		}

		MenuData.forEach( ( Value, Index ) => {
			if( Value.Child && Value.Child.length > 0 ){
				Value.Child.forEach( ( Child, ChildIndex ) => {
					switch( Child.Code.replace(/\s/g, '') ){
						case "BalanceSheet":
							ElementTemp.push(
								<Route key={"/BalanceSheet"} path={"/BalanceSheet"} element={<Layout><BalanceSheet/></Layout>} />
							)
							break
						case "IncomeStatement":
							ElementTemp.push(
								<Route key={"/IncomeStatement"} path={"/IncomeStatement"} element={<Layout><IncomeStatement/></Layout>} />
							)
							break
						case "Revenue":
							ElementTemp.push(
								<Route key={"/Revenue"} path={"/Revenue"} element={<Layout><Revenue/></Layout>} />
							)
							break
						default:
							break
					}
				})
			}
			else{
				switch( Value.Code.replace(/\s/g, '') ){
					case "ExecutiveHighlight":
						ElementTemp.push(
							<Route key={"/ExecutiveHighlight"} path={"/ExecutiveHighlight"} element={<Layout><ExecutiveHighlight/></Layout>} />
						);
						break
					case "F&BProduction":
						ElementTemp.push(
							<Route key={"/F&BProduction"} path={"/F&BProduction"} element={<Layout><FnBProduction/></Layout>} />
						);
						break
					case "F&BService":
						ElementTemp.push(
							<Route key={"/F&BService"} path={"/F&BService"} element={<Layout><FnBService/></Layout>} />
						);
						break
					case "GolfOperation":
						ElementTemp.push(
							<Route key={"/GolfOperation"} path={"/GolfOperation"} element={<Layout><GolfOperation/></Layout>} />
						);
						break
					case "NumberOfPlayer":
						ElementTemp.push(
							<Route key={"/NumberOfPlayer"} path={"/NumberOfPlayer"} element={<Layout><NumberOfPlayer/></Layout>} />
						);
						break
					case "Reservation":
						ElementTemp.push(
							<Route key={"/Reservation"} path={"/Reservation"} element={<Layout><Reservation/></Layout>} />
						);
						break
					case "SalesManagement":
						ElementTemp.push(
							<Route key={"/SalesManagement"}  path={"/SalesManagement"} element={<Layout><SalesManagement/></Layout>} />
						);
						break
					default:
						break
				}
			}
		})

		ElementTemp.push(
			<Route key="not-found" path="*" element={<NotFoundPath/>} />
		);

		return ElementTemp
	}

	useEffect(() => {
		if( localStorage.getItem( "Authentication" ) !== null ){
			FGenerateMenu()
		}
	}, [vAuthentication])

	return (
		<Routes>
			<Route path="/Sign/In" element={ ( vAuthentication === "" ) ? <SignIn /> : <Navigate to="/" /> } />
			<Route path="/Password/Forgot/Request" element={ ( vAuthentication === "" ) ? <ForgotPassword /> : <Navigate to="/Sign/In" /> } />
			<Route path="/Password/Forgot/Confirm" element={ ( vAuthentication === "") ? <ForgotPasswordC /> : <Navigate to="/Sign/In" />} />
			{GenerateMenuPath( MenuDataTemp, UserRoleTemp )}
		</Routes>
	)
}

export default Router