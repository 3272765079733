import CancelItemTable from "./CancelItemTable";
import FoodOverUnderDuration from "./FoodOverUnderDuration";

const FnBProduction = () => {
	return (
		<div className="container-fluid">
			<div className="content p-3">
				<div className="row shadow-lg pt-2 mb-2 bg-white rounded">
					<div className="col-12">
						<div>
							{/* <CancelItemTable /> */}
							<FoodOverUnderDuration/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FnBProduction