import "chart.js/auto";
import "react-datepicker/dist/react-datepicker.css";
import "toastr/build/toastr.min.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import toastr from "toastr";
import { vAPIURL, vGlobalDateNow, vBoolDev } from "../../Global";
import DatePicker from 'react-datepicker';
import format from 'date-fns/format';

const NOPTable = () => {
	const [ vSearchPlayerLoading, SetSearchPlayerLoading ] = useState( false )
	const [ vSearchPlayerData, SetSearchPlayerData ] = useState( [] );
	const [ vSearchPlayerStartDate, SetSearchPlayerStartDate ] = useState( new Date( vGlobalDateNow ) );
	const [ vSearchPlayerEndDate, SetSearchPlayerEndDate ] = useState( new Date( vGlobalDateNow ) );

	const FSearchPlayer = async () => {
		SetSearchPlayerLoading( true );
		let vStatus = 1;
		let vMessage = [];

		try{
			const vURL = vAPIURL + "/Golf/NumberOfPlayer";
			const vData = {
				Action: {
					Value: "Search",
				},
				Input: {
					StartDate: {
						Value: format( vSearchPlayerStartDate, 'dd-MMM-yyyy' ),
					},
					EndDate: {
						Value: format( vSearchPlayerEndDate, 'dd-MMM-yyyy' ),
					},
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};
			await axios
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;

						for( var i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}

						if( vStatus === 1 ){
							SetSearchPlayerData( vResponse.data.Output.Result )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "Error" );
					}
				);
		}catch( vError ){
			vStatus = 2;
			vMessage.push( "Error" );
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}

		SetSearchPlayerLoading( false ); 
	}

	useEffect(() => {
		FSearchPlayer();
	}, [])
	
	const AllFlightAM = vSearchPlayerData.map(Value => Value.AllFlightAM);
	const AllMemberAM = vSearchPlayerData.map(Value => Value.AllMemberAM);
	const AllNonMemberAM = vSearchPlayerData.map(Value => Value.AllNonMemberAM);
	const AllPlayerAM = parseInt(AllMemberAM)+parseInt(AllNonMemberAM);
	let AllPlayerPerFlightBookingAM = isNaN((AllPlayerAM / AllFlightAM).toFixed(2)) ? 0 : (AllPlayerAM / AllFlightAM).toFixed(2);
	
	const CheckInFlightAMFromBooking = vSearchPlayerData.map(Value => Value.CheckInFlightAMFromBooking);
	const CheckInMemberAMFromBooking = vSearchPlayerData.map(Value => Value.CheckInMemberAMFromBooking);
	const CheckInNonMemberAMFromBooking = vSearchPlayerData.map(Value => Value.CheckInNonMemberAMFromBooking);
	const CheckInPlayerAMFromBooking = parseInt(CheckInMemberAMFromBooking)+parseInt(CheckInNonMemberAMFromBooking);
	let PlayerPerFlightBookingAMFromBooking = isNaN((CheckInPlayerAMFromBooking / CheckInFlightAMFromBooking).toFixed(2)) ? 0 : (CheckInPlayerAMFromBooking / CheckInFlightAMFromBooking).toFixed(2);
	
	const CheckInFlightAMFromWalkIn = vSearchPlayerData.map(Value => Value.CheckInFlightAMFromWalkIn);
	const CheckInMemberAMFromWalkIn = vSearchPlayerData.map(Value => Value.CheckInMemberAMFromWalkIn);
	const CheckInNonMemberAMFromWalkIn = vSearchPlayerData.map(Value => Value.CheckInNonMemberAMFromWalkIn);
	const CheckInPlayerAMFromWalkIn = parseInt(CheckInMemberAMFromWalkIn)+parseInt(CheckInNonMemberAMFromWalkIn);
	let PlayerPerFlightBookingAMFromWalkIn = isNaN((CheckInPlayerAMFromWalkIn / CheckInFlightAMFromWalkIn).toFixed(2)) ? 0 : (CheckInPlayerAMFromWalkIn / CheckInFlightAMFromWalkIn).toFixed(2);

	const TotalFlightAM = parseInt(CheckInFlightAMFromBooking)+parseInt(CheckInFlightAMFromWalkIn);
	const TotalMemberAM = parseInt(CheckInMemberAMFromBooking)+parseInt(CheckInMemberAMFromWalkIn);
	const TotalNonMemberAM = parseInt(CheckInNonMemberAMFromBooking)+parseInt(CheckInNonMemberAMFromWalkIn);
	const TotalCheckInAM = parseInt(CheckInPlayerAMFromBooking)+parseInt(CheckInPlayerAMFromWalkIn);
	let PlayerPerFlightTotalAM = isNaN((TotalCheckInAM / TotalFlightAM).toFixed(2)) ? 0 : (TotalCheckInAM / TotalFlightAM).toFixed(2);

	let BookingAMReliability = isNaN((Math.round((CheckInPlayerAMFromBooking / AllPlayerAM * 100) * 100) / 100)) ? 0 : (Math.round((CheckInPlayerAMFromBooking / AllPlayerAM * 100) * 100) / 100);
	let MemberPercentageAM = isNaN((Math.round((CheckInMemberAMFromBooking / AllMemberAM * 100) * 100) / 100)) ? 0 : (Math.round((CheckInMemberAMFromBooking / AllMemberAM * 100) * 100) / 100);
	let NonMemberPercentageAM = isNaN((Math.round((CheckInNonMemberAMFromBooking / AllNonMemberAM * 100) * 100) / 100)) ? 0 : (Math.round((CheckInNonMemberAMFromBooking / AllNonMemberAM * 100) * 100) / 100);

	const AllFlightPM = vSearchPlayerData.map(Value => Value.AllFlightPM);
	const AllMemberPM = vSearchPlayerData.map(Value => Value.AllMemberPM);
	const AllNonMemberPM = vSearchPlayerData.map(Value => Value.AllNonMemberPM);
	const AllPlayerPM = parseInt(AllMemberPM)+parseInt(AllNonMemberPM);
	let AllPlayerPerFlightBookingPM = isNaN((AllPlayerPM / AllFlightPM).toFixed(2)) ? 0 : (AllPlayerPM / AllFlightPM).toFixed(2);
		
	const CheckInFlightPMFromBooking = vSearchPlayerData.map(Value => Value.CheckInFlightPMFromBooking);
	const CheckInMemberPMFromBooking = vSearchPlayerData.map(Value => Value.CheckInMemberPMFromBooking);
	const CheckInNonMemberPMFromBooking = vSearchPlayerData.map(Value => Value.CheckInNonMemberPMFromBooking);
	const CheckInPlayerPMFromBooking = parseInt(CheckInMemberPMFromBooking)+parseInt(CheckInNonMemberPMFromBooking);
	let PlayerPerFlightBookingPMFromBooking = isNaN((CheckInPlayerPMFromBooking / CheckInFlightPMFromBooking).toFixed(2)) ? 0 : (CheckInPlayerPMFromBooking / CheckInFlightPMFromBooking).toFixed(2);

	const CheckInFlightPMFromWalkIn = vSearchPlayerData.map(Value => Value.CheckInFlightPMFromWalkIn);
	const CheckInMemberPMFromWalkIn = vSearchPlayerData.map(Value => Value.CheckInMemberPMFromWalkIn);
	const CheckInNonMemberPMFromWalkIn = vSearchPlayerData.map(Value => Value.CheckInNonMemberPMFromWalkIn);
	const CheckInPlayerPMFromWalkIn = parseInt(CheckInMemberPMFromWalkIn)+parseInt(CheckInNonMemberPMFromWalkIn);
	let PlayerPerFlightBookingPMFromWalkIn = isNaN((CheckInPlayerPMFromWalkIn / CheckInFlightPMFromWalkIn).toFixed(2)) ? 0 : (CheckInPlayerPMFromWalkIn / CheckInFlightPMFromWalkIn).toFixed(2);
   
	const TotalFlightPM = parseInt(CheckInFlightPMFromBooking)+parseInt(CheckInFlightPMFromWalkIn);
	const TotalMemberPM = parseInt(CheckInMemberPMFromBooking)+parseInt(CheckInMemberPMFromWalkIn);
	const TotalNonMemberPM = parseInt(CheckInNonMemberPMFromBooking)+parseInt(CheckInNonMemberPMFromWalkIn);
	const TotalCheckInPM = parseInt(CheckInPlayerPMFromBooking)+parseInt(CheckInPlayerPMFromWalkIn);
	let PlayerPerFlightTotalPM = isNaN((TotalCheckInPM / TotalFlightPM).toFixed(2)) ? 0 : (TotalCheckInPM / TotalFlightPM).toFixed(2);

	let BookingPMReliability = isNaN((Math.round((CheckInPlayerPMFromBooking / AllPlayerPM * 100) * 100) / 100)) ? 0 : (Math.round((CheckInPlayerPMFromBooking / AllPlayerPM * 100) * 100) / 100);
	let MemberPercentagePM = isNaN((Math.round((CheckInMemberPMFromBooking / AllMemberPM * 100) * 100) / 100)) ? 0 : (Math.round((CheckInMemberPMFromBooking / AllMemberPM * 100) * 100) / 100);
	let NonMemberPercentagePM = isNaN((Math.round((CheckInNonMemberPMFromBooking / AllNonMemberPM * 100) * 100) / 100)) ? 0 : (Math.round((CheckInNonMemberPMFromBooking / AllNonMemberPM * 100) * 100) / 100);

	const TotalAllFlight = parseInt(AllFlightAM)+parseInt(AllFlightPM);
	const TotalAllMember = parseInt(AllMemberAM)+parseInt(AllMemberPM);
	const TotalAllNonMember = parseInt(AllNonMemberAM)+parseInt(AllNonMemberPM);
	const TotalAllPlayer = parseInt(AllPlayerAM)+parseInt(AllPlayerPM);
	let TotalAllPlayerPerFlightBooking = isNaN((TotalAllPlayer / TotalAllFlight).toFixed(2)) ? 0 : (TotalAllPlayer / TotalAllFlight).toFixed(2);
    
	const TotalCheckInFlightFromBooking = parseInt(CheckInFlightAMFromBooking)+parseInt(CheckInFlightPMFromBooking);
	const TotalCheckInMemberFromBooking = parseInt(CheckInMemberAMFromBooking)+parseInt(CheckInMemberPMFromBooking);
	const TotalCheckInNonMemberFromBooking = parseInt(CheckInNonMemberAMFromBooking)+parseInt(CheckInNonMemberPMFromBooking);
	const TotalCheckInPlayerFromBooking = parseInt(TotalCheckInMemberFromBooking)+parseInt(TotalCheckInNonMemberFromBooking);
	let TotalPlayerPerFlightBookingFromBooking = isNaN((TotalCheckInPlayerFromBooking / TotalCheckInFlightFromBooking).toFixed(2)) ? 0 : (TotalCheckInPlayerFromBooking / TotalCheckInFlightFromBooking).toFixed(2);

	const TotalCheckInFlightFromWalkIn = parseInt(CheckInFlightAMFromWalkIn)+parseInt(CheckInFlightPMFromWalkIn);
	const TotalCheckInMemberFromWalkIn = parseInt(CheckInMemberAMFromWalkIn)+parseInt(CheckInMemberPMFromWalkIn);
	const TotalCheckInNonMemberFromWalkIn = parseInt(CheckInNonMemberAMFromWalkIn)+parseInt(CheckInNonMemberPMFromWalkIn);
	const TotalCheckInPlayerFromWalkIn = parseInt(TotalCheckInMemberFromWalkIn)+parseInt(TotalCheckInNonMemberFromWalkIn);
	let TotalPlayerPerFlightBookingFromWalkIn = isNaN((TotalCheckInPlayerFromWalkIn / TotalCheckInFlightFromWalkIn).toFixed(2)) ? 0 : (TotalCheckInPlayerFromWalkIn / TotalCheckInFlightFromWalkIn).toFixed(2);
	
	const TotalFlight = parseInt(TotalFlightAM)+parseInt(TotalFlightPM);
	const TotalMember = parseInt(TotalMemberAM)+parseInt(TotalMemberPM);
	const TotalNonMember = parseInt(TotalNonMemberAM)+parseInt(TotalNonMemberPM);
	const TotalCheckIn = parseInt(TotalMember)+parseInt(TotalNonMember);
	let PlayerPerFlightTotal = isNaN((TotalCheckIn / TotalFlight).toFixed(2)) ? 0 : (TotalCheckIn / TotalFlight).toFixed(2);

	const TotalPlayerCheckInFromBooking = parseInt(CheckInPlayerAMFromBooking) + parseInt(CheckInPlayerPMFromBooking)
	let TotalBookingReliability = isNaN((Math.round((TotalPlayerCheckInFromBooking / TotalAllPlayer * 100) * 100) / 100)) ? 0 : (Math.round((TotalPlayerCheckInFromBooking / TotalAllPlayer * 100) * 100) / 100);
	let TotalMemberPercentage = isNaN((Math.round((TotalCheckInMemberFromBooking / TotalAllMember * 100) * 100) / 100)) ? 0 : (Math.round((TotalCheckInMemberFromBooking / TotalAllMember * 100) * 100) / 100);
	let TotalNonMemberPercentage = isNaN((Math.round((TotalCheckInNonMemberFromBooking / TotalAllNonMember * 100) * 100) / 100)) ? 0 : (Math.round((TotalCheckInNonMemberFromBooking / TotalAllNonMember * 100) * 100) / 100);

	const FlightCheckOutAM = vSearchPlayerData.map(Value => Value.FlightCheckOutAM);
	const MemberCheckOutAM = vSearchPlayerData.map(Value => Value.MemberCheckOutAM);
	const NonMemberCheckOutAM = vSearchPlayerData.map(Value => Value.NonMemberCheckOutAM);
	const PlayerAM = parseInt(MemberCheckOutAM)+parseInt(NonMemberCheckOutAM);
	let PlayerPerFlightCheckOutAM = isNaN((PlayerAM / FlightCheckOutAM).toFixed(2)) ? 0 : (PlayerAM / FlightCheckOutAM).toFixed(2);
	
	const FlightCheckOutPM = vSearchPlayerData.map(Value => Value.FlightCheckOutPM);
	const MemberCheckOutPM = vSearchPlayerData.map(Value => Value.MemberCheckOutPM);
	const NonMemberCheckOutPM = vSearchPlayerData.map(Value => Value.NonMemberCheckOutPM);
	const PlayerPM = parseInt(MemberCheckOutPM)+parseInt(NonMemberCheckOutPM);
	let PlayerPerFlightCheckOutPM = isNaN((PlayerPM / FlightCheckOutPM).toFixed(2)) ? 0 : (PlayerPM / FlightCheckOutPM).toFixed(2);
	
	const TotalFlightCheckOut = parseInt(FlightCheckOutAM)+parseInt(FlightCheckOutPM);
	const TotalMemberCheckOut = parseInt(MemberCheckOutAM)+parseInt(MemberCheckOutPM);
	const TotalNonMemberCheckOut = parseInt(NonMemberCheckOutAM)+parseInt(NonMemberCheckOutPM);
	const TotalCheckOut = parseInt(PlayerAM)+parseInt(PlayerPM);
	let PlayerPerFlightTotalCheckOut = isNaN((TotalCheckOut / TotalFlightCheckOut).toFixed(2)) ? 0 : (TotalCheckOut / TotalFlightCheckOut).toFixed(2);

	const AMFirstRowStyle = { backgroundColor: 'rgb(3, 255, 60)', fontWeight: 'bold' }
	const AMRowStyle = { backgroundColor: 'rgb(3, 255, 60)', fontWeight: 'bold', textAlign: 'center' }

	const PMFirstRowStyle = { backgroundColor: 'rgb(255, 94, 110)', fontWeight: 'bold' }
	const PMRowStyle = { backgroundColor: 'rgb(255, 94, 110)', fontWeight: 'bold', textAlign: 'center' }

	const TotalFirstRowStyle = { backgroundColor: 'rgb(0, 244, 255)', fontWeight: 'bold' }
	const TotalRowStyle = { backgroundColor: 'rgb(0, 244, 255)', fontWeight: 'bold', textAlign: 'center' }

	return (
		<div className="card bg-gradient-light">
			<div className="card-header bg-success">
				<h3 className="card-title">
					SUMMARY NUMBER OF PLAYER
				</h3>
			</div>
			<div className="card-body">
				<div className="row">
					<div className="col-6 col-md-2">
						<div className="form-group">
							<label>
								Start Date
							</label>
							<DatePicker
								dateFormat="dd-MMM-yyyy"
								selected={ vSearchPlayerStartDate }
								onChange={e => SetSearchPlayerStartDate(e)}
								className="form-control"
								value={ vSearchPlayerStartDate }
							/>
						</div>
					</div>
					<div className="col-6 col-md-2">
						<div className="form-group">
							<label>
								End Date
							</label>
							<DatePicker
								dateFormat="dd-MMM-yyyy"
								selected={ vSearchPlayerEndDate }
								onChange={e => SetSearchPlayerEndDate(e)}
								className="form-control"
								value={ vSearchPlayerEndDate }
							/>
						</div>
					</div>
					<div className="col-12 col-md-2">
						<button
							onClick={ FSearchPlayer }
							type="submit"
							className="btn btn-primary btn-srh-nop"
						>
							<i className="fas fa-search">
							</i>
							&nbsp;
							Search
						</button>
					</div>
				</div>
			</div>
			{
				vSearchPlayerLoading?
					<div className="d-flex justify-content-center">
						<i className="fas fa-3x fa-sync-alt fa-spin"></i>
					</div>
				:
				<div className="container-table shadow">
					<div className="detail-table">
						<table id="table-nop" className="table table-striped table-valign-middle">
							{
								vBoolDev 
								?
									null
								:
								<thead>
									<tr>
										<td colSpan={4}></td>
										<td className="text-center font-weight-bold">F, FN, M, TNI</td>
										<td className="text-center font-weight-bold">AG, UG</td>
									</tr>
								</thead>
							}
							<thead>
								<tr>
									<td className="text-center font-weight-bold">STATUS</td>
									<td className="text-center font-weight-bold">FLIGHT</td>
									<td className="text-center font-weight-bold">PLAYER</td>
									<td className="text-center font-weight-bold"></td>
									<td className="text-center font-weight-bold">M</td>
									<td className="text-center font-weight-bold">G</td>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td style={AMFirstRowStyle}>Booking AM</td>
									<td style={AMRowStyle}>{AllFlightAM}</td>
									<td style={AMRowStyle}>{AllPlayerAM} ({AllPlayerPerFlightBookingAM})</td>
									<td style={AMRowStyle}></td>
									<td style={AMRowStyle}>{AllMemberAM}</td>
									<td style={AMRowStyle}>{AllNonMemberAM}</td>
								</tr>
								<tr>
									<td className="bg-success font-weight-bold">Check-In AM (Booking)</td>
									<td className="bg-success font-weight-bold text-center">{CheckInFlightAMFromBooking}</td>
									<td className="bg-success font-weight-bold text-center">{CheckInPlayerAMFromBooking} ({PlayerPerFlightBookingAMFromBooking})</td>
									<td className="bg-success font-weight-bold text-center"></td>
									<td className="bg-success font-weight-bold text-center">{CheckInMemberAMFromBooking}</td>
									<td className="bg-success font-weight-bold text-center">{CheckInNonMemberAMFromBooking}</td>
								</tr>
								<tr>
									<td className="bg-success font-weight-bold">Check-In AM (Walk-In)</td>
									<td className="bg-success font-weight-bold text-center">{CheckInFlightAMFromWalkIn}</td>
									<td className="bg-success font-weight-bold text-center">{CheckInPlayerAMFromWalkIn} ({PlayerPerFlightBookingAMFromWalkIn})</td>
									<td className="bg-success font-weight-bold text-center"></td>
									<td className="bg-success font-weight-bold text-center">{CheckInMemberAMFromWalkIn}</td>
									<td className="bg-success font-weight-bold text-center">{CheckInNonMemberAMFromWalkIn}</td>
								</tr>
								<tr>
									<td className="bg-success font-weight-bold">Total Check-In AM</td>
									<td className="bg-success font-weight-bold text-center">{TotalFlightAM}</td>
									<td className="bg-success font-weight-bold text-center">{TotalCheckInAM} ({PlayerPerFlightTotalAM})</td>
									<td className="bg-success font-weight-bold text-center"></td>
									<td className="bg-success font-weight-bold text-center">{TotalMemberAM}</td>
									<td className="bg-success font-weight-bold text-center">{TotalNonMemberAM}</td>
								</tr>
								<tr>
									<td className="bg-success font-weight-bold">Check-Out AM</td>
									<td className="bg-success font-weight-bold text-center">{FlightCheckOutAM}</td>
									<td className="bg-success font-weight-bold text-center">{PlayerAM} ({PlayerPerFlightCheckOutAM})</td>
									<td className="bg-success font-weight-bold text-center"></td>
									<td className="bg-success font-weight-bold text-center">{MemberCheckOutAM}</td>
									<td className="bg-success font-weight-bold text-center">{NonMemberCheckOutAM}</td>
								</tr>
								<tr>
									<td style={AMFirstRowStyle}>Booking AM Reliability</td>
									<td style={AMRowStyle}></td>
									<td style={AMRowStyle}>{BookingAMReliability} %</td>
									<td style={AMRowStyle}></td>
									<td style={AMRowStyle}>{MemberPercentageAM} %</td>
									<td style={AMRowStyle}>{NonMemberPercentageAM} %</td>
								</tr>
									<td colSpan={6}>
									</td>
								<tr>
									<td style={PMFirstRowStyle}>Booking PM</td>
									<td style={PMRowStyle}>{AllFlightPM}</td>
									<td style={PMRowStyle}>{AllPlayerPM} ({AllPlayerPerFlightBookingPM})</td>
									<td style={PMRowStyle}></td>
									<td style={PMRowStyle}>{AllMemberPM}</td>
									<td style={PMRowStyle}>{AllNonMemberPM}</td>
								</tr>
								<tr>
									<td className="bg-danger font-weight-bold">Check-In PM (Booking)</td>
									<td className="bg-danger font-weight-bold text-center">{CheckInFlightPMFromBooking}</td>
									<td className="bg-danger font-weight-bold text-center">{CheckInPlayerPMFromBooking} ({PlayerPerFlightBookingPMFromBooking})</td>
									<td className="bg-danger font-weight-bold text-center"></td>
									<td className="bg-danger font-weight-bold text-center">{CheckInMemberPMFromBooking}</td>
									<td className="bg-danger font-weight-bold text-center">{CheckInNonMemberPMFromBooking}</td>
								</tr>
								<tr>
									<td className="bg-danger font-weight-bold">Check-In PM (Walk-In)</td>
									<td className="bg-danger font-weight-bold text-center">{CheckInFlightPMFromWalkIn}</td>
									<td className="bg-danger font-weight-bold text-center">{CheckInPlayerPMFromWalkIn} ({PlayerPerFlightBookingPMFromWalkIn})</td>
									<td className="bg-danger font-weight-bold text-center"></td>
									<td className="bg-danger font-weight-bold text-center">{CheckInMemberPMFromWalkIn}</td>
									<td className="bg-danger font-weight-bold text-center">{CheckInNonMemberPMFromWalkIn}</td>
								</tr>
								<tr>
									<td className="bg-danger font-weight-bold">Total Check-In PM</td>
									<td className="bg-danger font-weight-bold text-center">{TotalFlightPM}</td>
									<td className="bg-danger font-weight-bold text-center">{TotalCheckInPM} ({PlayerPerFlightTotalPM})</td>
									<td className="bg-danger font-weight-bold text-center"></td>
									<td className="bg-danger font-weight-bold text-center">{TotalMemberPM}</td>
									<td className="bg-danger font-weight-bold text-center">{TotalNonMemberPM}</td>
								</tr>
								<tr>
									<td className="bg-danger font-weight-bold">Check-Out PM</td>
									<td className="bg-danger font-weight-bold text-center">{FlightCheckOutPM}</td>
									<td className="bg-danger font-weight-bold text-center">{PlayerPM} ({PlayerPerFlightCheckOutPM})</td>
									<td className="bg-danger font-weight-bold text-center"></td>
									<td className="bg-danger font-weight-bold text-center">{MemberCheckOutPM}</td>
									<td className="bg-danger font-weight-bold text-center">{NonMemberCheckOutPM}</td>
								</tr>
								<tr>
									<td style={PMFirstRowStyle}>Booking PM Reliability</td>
									<td style={PMRowStyle}></td>
									<td style={PMRowStyle}>{BookingPMReliability} %</td>
									<td style={PMRowStyle}></td>
									<td style={PMRowStyle}>{MemberPercentagePM} %</td>
									<td style={PMRowStyle}>{NonMemberPercentagePM} %</td>
								</tr>
									<td colSpan={6}>
									</td>
								<tr>
									<td style={TotalFirstRowStyle}>Total Booking</td>
									<td style={TotalRowStyle}>{TotalAllFlight}</td>
									<td style={TotalRowStyle}>{TotalAllPlayer} ({TotalAllPlayerPerFlightBooking})</td>
									<td style={TotalRowStyle}></td>
									<td style={TotalRowStyle}>{TotalAllMember}</td>
									<td style={TotalRowStyle}>{TotalAllNonMember}</td>
								</tr>
								<tr>
									<td className="bg-primary font-weight-bold">Total Check-In (Booking)</td>
									<td className="bg-primary font-weight-bold text-center">{TotalCheckInFlightFromBooking}</td>
									<td className="bg-primary font-weight-bold text-center">{TotalCheckInPlayerFromBooking} ({TotalPlayerPerFlightBookingFromBooking})</td>
									<td className="bg-primary font-weight-bold text-center"></td>
									<td className="bg-primary font-weight-bold text-center">{TotalCheckInMemberFromBooking}</td>
									<td className="bg-primary font-weight-bold text-center">{TotalCheckInNonMemberFromBooking}</td>
								</tr>
								<tr>
									<td className="bg-primary font-weight-bold">Total Check-In (Walk-In)</td>
									<td className="bg-primary font-weight-bold text-center">{TotalCheckInFlightFromWalkIn}</td>
									<td className="bg-primary font-weight-bold text-center">{TotalCheckInPlayerFromWalkIn} ({TotalPlayerPerFlightBookingFromWalkIn})</td>
									<td className="bg-primary font-weight-bold text-center"></td>
									<td className="bg-primary font-weight-bold text-center">{TotalCheckInMemberFromWalkIn}</td>
									<td className="bg-primary font-weight-bold text-center">{TotalCheckInNonMemberFromWalkIn}</td>
								</tr>
								<tr>
									<td className="bg-primary font-weight-bold">Total Check-In</td>
									<td className="bg-primary font-weight-bold text-center">{TotalFlight}</td>
									<td className="bg-primary font-weight-bold text-center">{TotalCheckIn} ({PlayerPerFlightTotal})</td>
									<td className="bg-primary font-weight-bold text-center"></td>
									<td className="bg-primary font-weight-bold text-center">{TotalMember}</td>
									<td className="bg-primary font-weight-bold text-center">{TotalNonMember}</td>
								</tr>
								<tr>
									<td className="bg-primary font-weight-bold">Total Check-Out</td>
									<td className="bg-primary font-weight-bold text-center">{TotalFlightCheckOut}</td>
									<td className="bg-primary font-weight-bold text-center">{TotalCheckOut} ({PlayerPerFlightTotalCheckOut})</td>
									<td className="bg-primary font-weight-bold text-center"></td>
									<td className="bg-primary font-weight-bold text-center">{TotalMemberCheckOut}</td>
									<td className="bg-primary font-weight-bold text-center">{TotalNonMemberCheckOut}</td>
								</tr>
								<tr>
									<td style={TotalFirstRowStyle}>Total Booking Reliability</td>
									<td style={TotalRowStyle}></td>
									<td style={TotalRowStyle}>{TotalBookingReliability} %</td>
									<td style={TotalRowStyle}></td>
									<td style={TotalRowStyle}>{TotalMemberPercentage} %</td>
									<td style={TotalRowStyle}>{TotalNonMemberPercentage} %</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			}     
		</div>
	);
};

export default NOPTable