import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, registerables  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import MonthlyDate from './MonthlyDate';
import { vAPIURL, vBoolDev, vGlobalDateNow, CheckScreenWidth } from '../../Global';
import axios from 'axios';
import toastr from 'toastr';
import format from 'date-fns/format';

ChartJS.register(...registerables )

const ChartRoomMonthly = () => {

	const [TotalQtyMonthly, SetTotalQtyMonthly] = useState([]);
	const [loading, setLoading] = useState(false)

	const GetTotalQtyMonthly = async () => {
		setLoading(true);
		let vStatus = 1;
		let vMessage = [];

		try{
			const vURL = vAPIURL + "/Golf/Reservation/RoomMonthly";
			const vData = {
				"Action": {
					"Value": "Search",
				},
				Input: {
					Date: {
						Value: format( new Date( vGlobalDateNow ) , 'dd-MMM-yyyy' ),
					},
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};

			await axios
				.post(vURL, vData, vConfig)
				.then(
					(vResponse) => {
						vStatus = vResponse.data.Status;
						
						for( var i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}

						if( vStatus === 1 ){
							SetTotalQtyMonthly( vResponse.data.Output.Result )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "Error" );
					}
				);
		}catch( vError ){
			vStatus = 2;
			vMessage.push( "Error" );
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
				//toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}

		setLoading( false );
	}

	useEffect(() => {
		GetTotalQtyMonthly()
	}, [])  

	let DataVVIP = TotalQtyMonthly.map(row => row.VVIP)
	let DataVIP1 = TotalQtyMonthly.map(row => row.VIP1)
	let DataVIP2 = TotalQtyMonthly.map(row => row.VIP2)
	let DataVIP3 = TotalQtyMonthly.map(row => row.VIP3)
	let DataVIP4 = TotalQtyMonthly.map(row => row.VIP4)
	let DataVIP5 = TotalQtyMonthly.map(row => row.VIP5)
	let DataBALLROOM = TotalQtyMonthly.map(row => row.BALLROOM)
	const DataAll = DataVVIP.concat(DataVIP1,DataVIP2,DataVIP3,DataVIP4,DataVIP5,DataBALLROOM);

	const Color = [
		'rgb(255, 99, 132)',
		'rgb(3, 252, 227)',
		'rgb(3, 252, 15)',
		'rgb(252, 252, 3)',
		'rgb(252, 136, 3)',
		'rgb(171, 196, 255)',
		'rgb(255, 238, 147)'
	]
	
	let DataChartLabel = []

	if ( vBoolDev == true ){
		DataChartLabel = ['VIP1','VIP2', 'VIP3', 'VIP4', 'VIP5', 'VIP6', 'BALLROOM']
	}
	else{
		DataChartLabel = ['VVIP MINANGKABAU','VIP1 YOGYAKARTA', 'VIP2 DENPASAR', 'VIP3 BANDA ACEH', 'VIP4 MANADO', 'VIP5 JAKARTA', 'BALLROOM']
	}

	let data = {
		labels: DataChartLabel,
		datasets: [{
			label: 'Reserved', 
			data: DataAll,
			backgroundColor: Color,
			borderColor: Color,
			borderWidth: 1 
		}]
	};

	let option = {
		maintainAspecRatio: true,
		responsive : true,
		scales: {
			x: {
				beginAtZero: true
			},
			y: {
				beginAtZero: true
			}
		},
		plugins: {
			legend: {
				display: false,
				position: 'top',
				align: 'center',
				labels: {
					boxHeight: 5,
					boxWidth: 10,
					textAlign: 'left'
				}
			},
			tooltip: {
				callbacks: {
					label: ((tooltipItem, data) => {
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 0) {
							if ( vBoolDev == true ){
								return 'VIP1';
							}
							else{
								return 'VVIP MINANGKABAU';
							}
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 1) {
							if ( vBoolDev == true ){
								return 'VIP2';
							}
							else{
								return 'VIP1 YOGYAKARTA';
							}
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 2) {
							if ( vBoolDev == true ){
								return 'VIP3';
							}
							else{
								return 'VIP2 DENPASAR';
							}
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 3) {
							if ( vBoolDev == true ){
								return 'VIP4';
							}
							else{
								return 'VIP3 BANDA ACEH';
							}
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 4) {
							if ( vBoolDev == true ){
								return 'VIP5';
							}
							else{
								return 'VIP4 MANADO';
							}
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 5) {
							if ( vBoolDev == true ){
								return 'VIP6';
							}
							else{
								return 'VIP5 JAKARTA';
							}
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 6) {
							return 'BALLROOM';
						}
					}
					),
					title: function(tooltipItem, data) {
						return '';
					}
				}
			},
			datalabels: {
				anchor: 'center',
				align: 'center',
				display: 'true',
				font: {
					size: 12,
				}
			}
		},        
	}

	return (
		<>
			{
				CheckScreenWidth ?
				<div className="card bg-gradient-light">
					<div className="card-header">
						<ul className="nav nav-pills ml-auto">
							<li className="nav-item text-info font-weight-bold">
								ROOM USAGE CHART MONTH TO DATE
							</li>
						</ul>
						<ul className="nav nav-pills ml-auto">
							<li className="nav-item text-info">
								(In Qty)
							</li>
						</ul>
						<div>
							<MonthlyDate />
						</div>
					</div>
					<div className="card-body">
						<div className="tab-content p-0 ">
							{loading ?
								<div className="d-flex justify-content-center">
									<i className="fas fa-3x fa-sync-alt fa-spin"></i>
								</div>
								:
								<>
									<div>
										<Bar
											data={data}
											plugins={[ChartDataLabels]}
											options={option}
											height={100}
										/>
									</div>
								</>
							}
						</div>
					</div>
				</div>
				:
				<div className="card bg-gradient-light">
					<div className="card-header">
						<ul className="nav nav-pills ml-auto">
							<li className="nav-item text-info font-weight-bold">
								ROOM USAGE CHART MONTH TO DATE
							</li>
						</ul>
						<ul className="nav nav-pills ml-auto">
							<li className="nav-item text-info">
								(In Qty)
							</li>
						</ul>
						<div>
							<MonthlyDate />
						</div>
					</div>
					<div className="card-body">
						<div className="tab-content p-0 ">
							{loading ?
								<div className="d-flex justify-content-center">
									<i className="fas fa-3x fa-sync-alt fa-spin"></i>
								</div>
								:
								<>
									<div>
										<Bar
											data={data}
											plugins={[ChartDataLabels]}
											options={option}
											height={300}
										/>
									</div>
								</>
							}
						</div>
					</div>
				</div>
			}
		</>
	)
}

export default ChartRoomMonthly