import React, { useEffect }from "react";

const NotFoundPath = () => {
	let vAuthentication = "";

	if( localStorage.getItem( "Authentication" ) !== null ){
		vAuthentication = localStorage.getItem( "Authentication" );
	}

	useEffect(() => {
		const redirectTimer = setTimeout(() => {
			if( vAuthentication === "" ){
				window.location.replace( "/Sign/In" );
			}
			else{
				window.location.replace( "/" );
			}
		}, 5000);
	
		return () => clearTimeout(redirectTimer);
	}, [vAuthentication]);
		
	return (
		<div
			style={{
				display: 'flex'
				, justifyContent: 'center'
				, alignItems: 'center'
			}}
		>
			<h2>
				404 - Not Found
			</h2>
		</div>
	)
}

export default NotFoundPath