import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import axios from "axios";
import { vAPIURL, vGlobalDateNow, vBoolDev } from "../../Global";
import toastr from "toastr";
import format from 'date-fns/format';

const TableContributionER = () => {
	const [ERData, SetERData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [disable, setDisable] = useState(0);
	
	const Today = new Date( vGlobalDateNow );
	let options = {
		weekday: "long",
		year: "numeric",
		month: "long",
		day: "numeric",
	};
	const Tanggal = Today.toLocaleDateString("en-GB", options);

	let header = "TODAY";
	if (disable === 0) {
		header = "TODAY";
	} else if (disable === 1) {
		header = "WEEK TO DATE";
	} else if (disable === 2) {
		header = "MONTH TO DATE";
	} else header = "YEAR TO DATE";

	const GetERData = async () => {
		setLoading(true);
		let vStatus = 1;
		let vMessage = [];
		try {
			const vURL = vBoolDev == true ? vAPIURL + "/Golf/Reservation/ER" : vAPIURL + "/Golf/Reservation/NewER";
			const DataToday = {
				Action: {
					Value: "Search",
				},
				Input: {
					Time: {
						Type: "Today"
						, Value: format( new Date( vGlobalDateNow ) , 'dd-MMM-yyyy' )
					},
				},
			};
			const DataWeekly = {
				Action: {
					Value: "Search",
				},
				Input: {
					Time: {
						Type: "Weekly"
						, Value: format( new Date( vGlobalDateNow ) , 'dd-MMM-yyyy' )
					},
				},
			};
			const DataMonthly = {
				Action: {
					Value: "Search",
				},
				Input: {
					Time: {
						Type: "Monthly"
						, Value: format( new Date( vGlobalDateNow ) , 'dd-MMM-yyyy' ),
					},
				},
			};
			const DataYearly = {
				Action: {
					Value: "Search",
				},
				Input: {
					Time: {
						Type: "Yearly"
						, Value: format( new Date( vGlobalDateNow ) , 'dd-MMM-yyyy' ),
					},
				},
			};

			let data;
			if (disable === 0) {
				data = DataToday;
			} else if (disable === 1) {
				data = DataWeekly;
			} else if (disable === 2) {
				data = DataMonthly;
			} else {
				data = DataYearly;
			}

			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};

			const vResponse = await axios.post(vURL, data, vConfig);

			vStatus = vResponse.data.Status;

			for (var i = 0; i < vResponse.data.Message.length; i++) {
				vMessage.push(vResponse.data.Message[i]);
			}
			if (vStatus === 1) {
				SetERData(vResponse.data.Output.Result);
			}
		} catch (vError) {
			vStatus = 2;
			vMessage.push("Error");
		}

		if (vMessage.length > 0) {
			if (vStatus === 1) {
				//toastr.success( vMessage.join( "</br>" ) );
			} else {
				toastr.error(vMessage.join("</br>"));
			}
		}
		setLoading(false);
	};

	useEffect(() => {
		GetERData();
	}, [disable]);

	let SumBooking = 0;
	ERData.forEach((Data) => {
		SumBooking += parseInt(Data.QtyBook);
	});

	let SumActual = 0;
	ERData.forEach((Data) => {
		SumActual += parseInt(Data.QtyAct);
	});

	const NormaliseBooking = (value) => ((value - 0) * 100) / (SumBooking - 0);
	const NormaliseActual = (value) => ((value - 0) * 100) / (SumActual - 0);
	let AvgReliability = (
		Math.round(NormaliseBooking(SumActual) * 100) / 100
	).toFixed(1);

	function FormatData(
		Username,
		QtyBook,
		QtyAct,
		BookingContribution,
		Reliability,
		ActualContribution
	) {
		const NormaliseReliability = (value) => ((value - 0) * 100) / (QtyBook - 0);
		BookingContribution = NormaliseBooking(QtyBook);
		BookingContribution = (Math.round(BookingContribution * 100) / 100).toFixed(1);
		BookingContribution = isNaN(BookingContribution) ? 0 : BookingContribution;
		ActualContribution = NormaliseActual(QtyAct);
		ActualContribution = (Math.round(ActualContribution * 100) / 100).toFixed(1);
		ActualContribution = isNaN(ActualContribution) ? 0 : ActualContribution;
		Reliability = NormaliseReliability(QtyAct);
		Reliability = (Math.round(Reliability * 100) / 100).toFixed(1);
		Reliability = isNaN(Reliability) ? 0 : Reliability;

		return {
			Username,
			QtyBook,
			QtyAct,
			BookingContribution,
			Reliability,
			ActualContribution,
		};
	}

	const rows = ERData.map((Data) => {
		const { Username, QtyBook, QtyAct, BookingContribution, Reliability } =
			Data;
		return FormatData(
			Username,
			QtyBook,
			QtyAct,
			BookingContribution,
			Reliability
		);
	});

	const ActualContribution = ERData.map(Value => parseInt(Value.QtyAct))
	const BookingContribution = ERData.map(Value => parseInt(Value.QtyBook))
	const Reliability = rows.map(Value => parseFloat(Value.Reliability))

	function GetTop3Index(arr) {
		const Top3Index = [];
	  
		for (let i = 0; i < arr.length; i++) {
			if (Top3Index.length < 3){
				Top3Index.push(i);
			}
			else{
				let minIndex = 0;
				for (let j = 1; j < 3; j++) {
				if (arr[Top3Index[j]] < arr[Top3Index[minIndex]]) {
					minIndex = j;
				}
				}
				if (arr[i] > arr[Top3Index[minIndex]]) {
					Top3Index[minIndex] = i;
				}
			}
		}
		return Top3Index;
	}

	function GetBottom3Index(arr) {
		const Bottom3Index = [];
		for (let i = 0; i < arr.length; i++) {
			if (Bottom3Index.length < 3){
				Bottom3Index.push(i);
			} 
			else{
				let maxIndex = 0;
				for (let j = 1; j < Bottom3Index.length; j++) {
				if (arr[Bottom3Index[j]] > arr[Bottom3Index[maxIndex]]) {
					maxIndex = j;
				}
				}
				if (arr[i] < arr[Bottom3Index[maxIndex]]) {
					Bottom3Index[maxIndex] = i;
				}
			}
		}
		return Bottom3Index;
	}

	const Top3ActualContribution = GetTop3Index(ActualContribution);
	const Bottom3ActualContribution = GetBottom3Index(ActualContribution);

	const Top3BookingContribution = GetTop3Index(BookingContribution);
	const Bottom3BookingContribution= GetBottom3Index(BookingContribution);
	
	const Top3Reliability = GetTop3Index(Reliability);
	const Bottom3Reliability = GetBottom3Index(Reliability);

	const [ vTimer, SetTimer ] = useState(0);

	useEffect(() => {
		const timer = setTimeout(() => {
			GetERData()
				SetTimer( 600000 );
			}, vTimer);
		return () => clearTimeout(timer);
	})

	function ButtonLogic() {
		return (
			<Box>
				<Button
					sx={{ mr: 2 }}
					disabled={disable === 0}
					onClick={() => setDisable(0)}
					variant="contained"
				>
					Today
				</Button>
				<Button
					sx={{ mr: 2 }}
					disabled={disable === 1}
					onClick={() => setDisable(1)}
					variant="contained"
				>
					WTD
				</Button>
				<Button
					sx={{ mr: 2 }}
					disabled={disable === 2}
					onClick={() => setDisable(2)}
					variant="contained"
				>
					MTD
				</Button>
				<Button
					disabled={disable === 3}
					onClick={() => setDisable(3)}
					variant="contained"
				>
					YTD
				</Button>
			</Box>
		);
	}

	return (
		<div className="card bg-gradient-light">
			<div className="card-header">
				<ul className="nav nav-pills ml-auto">
					<h3 className="card-title text-success font-weight-bold">
						RESERVATION STAFF PRODUCTIVITY RATE
					</h3>
				</ul>
				<ul className="nav nav-pills ml-auto">
					<h5 className="card-title text-info font-weight-bold">
						{header}
					</h5>
				</ul>
				<ul className="nav nav-pills ml-auto">
					<h6 className="nav-item text-info">
						{Tanggal}
					</h6>
				</ul>
			</div>
			{loading ? 
				<div className="d-flex justify-content-center">
					<i className="fas fa-3x fa-sync-alt fa-spin"></i>
				</div>
			:
				<>
					<div align="center">
						<ButtonLogic />
					</div>
					<TableContainer component={Paper}>
						<Table size="small" aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell sx={{ border: 1, fontWeight: 'bold' }} rowSpan={2}>
										STAFF
									</TableCell>
									<TableCell sx={{ border: 1, fontWeight: 'bold' }} align="center" colSpan={2}>
										RESERVATION
									</TableCell>
									<TableCell sx={{ border: 1, fontWeight: 'bold' }} align="center" colSpan={2}>
										ACTUAL
									</TableCell>
									<TableCell sx={{ border: 1, width: 150, fontWeight: 'bold' }} rowSpan={2} align="center">
										RESERVATION RELIABILITY
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell sx={{ border: 1, width: 150, fontWeight: 'bold' }} align="center">
										PAX
									</TableCell>
									<TableCell sx={{ border: 1, width: 150, fontWeight: 'bold' }} align="center">
										%
									</TableCell>
									<TableCell sx={{ border: 1, width: 150, fontWeight: 'bold' }} align="center">
										PAX
									</TableCell>
									<TableCell sx={{ border: 1, width: 150, fontWeight: 'bold' }} align="center">
										%
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{rows.map((row, Index) => (
									<TableRow key={row.Username} sx={{ border: 1 }}>
										<TableCell sx={{ border: 1 }} component="th" scope="row">
											{row.Username}
										</TableCell>
										<TableCell sx={{ border: 1 }} align="right">
											{row.QtyBook}
										</TableCell>
										<TableCell 
											sx={{ 
												border: 1,
												backgroundColor: 
													Top3BookingContribution.includes(Index) ? 'blue' :
													Bottom3BookingContribution.includes(Index) ? 'red' :""
											}} 
											align="right"
										>
											<p
												style={{
													color: 
														Top3BookingContribution.includes(Index) ? 'white' :
														Bottom3BookingContribution.includes(Index) ? 'white' : "black"
												}}
											>
												{row.BookingContribution} %
											</p>
										</TableCell>
										<TableCell sx={{ border: 1 }} align="right">
											{row.QtyAct}
										</TableCell>
										<TableCell sx={{ 
												border: 1, 
												backgroundColor: 
													Top3ActualContribution.includes(Index) ? 'blue' :
													Bottom3ActualContribution.includes(Index) ? 'red' :"",
											}} 
											align="right"
										>
											<p
												style={{
													color: 
														Top3ActualContribution.includes(Index) ? 'white' :
														Bottom3ActualContribution.includes(Index) ? 'white' : "black"
												}}
											>
												{row.ActualContribution} %
											</p>
										</TableCell>
										<TableCell sx={{ 
												border: 1, 
												backgroundColor: 
													Top3Reliability.includes(Index) ? 'blue' :
													Bottom3Reliability.includes(Index) ? 'red' :""
											}}
											align="right"
										>
											<p
												style={{
													color: 
														Top3Reliability.includes(Index) ? 'white' :
														Bottom3Reliability.includes(Index) ? 'white' : "black"
												}}
											>
												{row.Reliability} %
											</p>
										</TableCell>
									</TableRow>
								))}
								<TableRow>
									<TableCell sx={{ border: 1, fontWeight: 'bold' }}>
										TOTAL
									</TableCell>
									<TableCell sx={{ border: 1, fontWeight: 'bold' }} align="right">
										{SumBooking}
									</TableCell>
									<TableCell sx={{ border: 1, fontWeight: 'bold' }} align="right">
										100 %
									</TableCell>
									<TableCell sx={{ border: 1, fontWeight: 'bold' }} align="right">
										{SumActual}
									</TableCell>
									<TableCell sx={{ border: 1, fontWeight: 'bold' }} align="right">
										100 %
									</TableCell>
									<TableCell sx={{ border: 1, fontWeight: 'bold' }} align="right">
										{AvgReliability}%
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</>
			}
		</div>
	);
};
export default TableContributionER