import { React, useState, useLayoutEffect } from 'react';
import format from 'date-fns/format';
import toastr from "toastr";
import axios from "axios";
import { vAPIURL, vGlobalDateNow } from '../Global';
import { Avatar } from "@mui/material";
import ProfilePopUp from '../Widget/ProfilePopUp';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from 'react-router-dom';

const Header = ( { ProfileData, ImageProfile = "", UserRole = "" } ) => {
	const [ vFirstLoad, SetFirstLoad ] =  useState( false )
	const [ isVisible, setIsVisible ] = useState( false )
	let AccessSetting = false;

	const toggleVisibility = () => {
	  setIsVisible( !isVisible )
	}

	const FSignCheck = async () => {
		let vStatus = 1;
		let vMessage = [];

		try{
			const vURL = vAPIURL + "/Sign/CheckV2"
			const vData = {}
			const vConfig = {
				headers: {
					"Content-Type": "application/json"
					, Authentication: `${localStorage.getItem("Authentication")}`
				}
			}

			await axios
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;

						for( let i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}

						if( vResponse.data.Session.Sign !== undefined && vResponse.data.Session.Sign !== null ){
							if( vResponse.data.Session.Sign === 2 ){
								localStorage.clear();
								window.location.replace("/");								
							}
						}

						if( vResponse.data.Session.OTP !== undefined && vResponse.data.Session.OTP !== null ){
							if( vResponse.data.Session.OTP === 2 ){
								window.location.replace("/Sign/OTP");								
							}
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2
						vMessage.push( "Server Problem" )
					}
				)
		}
		catch( vError ){
			vStatus = 2
			vMessage.push( "Server Problem" )
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
				toastr.success( vMessage.join( "</br>" ) )
			}
			else{
				toastr.error( vMessage.join( "</br>" ) )
				localStorage.clear();
				window.location.replace("/Sign/In");
			}
		}
	}

	useLayoutEffect(() => {
		if( vFirstLoad === false ){
			SetFirstLoad( true );
			FSignCheck();
			return;
		}
	})

	if( UserRole === "SYSADMIN" ){
		AccessSetting = true
	}
	else if ( UserRole === "DASH_ADM" ){
		AccessSetting = true
	}
	else{
		AccessSetting = false
	}

	return (
		<div>
			<nav className="main-header navbar navbar-expand navbar-white navbar-light">
				<ul className="navbar-nav">
					<li className="nav-item">
						<a
							className="nav-link"
							data-widget="pushmenu"
							role="button"
						>
							<i className="fas fa-bars"></i>
						</a>
					</li>
					<li className="nav-item">
						<div className="nav-link text-secondary font-weight-bold">
							{format( new Date( vGlobalDateNow ), 'eeee, dd-MMM-yyyy' ).toUpperCase()}
						</div>
					</li>
				</ul>
				<ul className="navbar-nav ml-auto">
					{
						AccessSetting 
						?
						<li className="nav-item">
							<Link to={"/SettingMenuUser"} className="nav-link">
								<SettingsIcon/>
							</Link>
						</li>
						:
						null
					}
					<li className="nav-item">
						<div
							className="nav-link"
							data-widget="fullscreen"
							role="button"
						>
							<i className="fas fa-expand-arrows-alt"/>
						</div>
					</li>
					<li className="nav-item">
						<div
							className="nav-link"
							role="button"
							onClick={toggleVisibility}
						>
							<Avatar sx={{ width: 24, height: 24 }} src={ImageProfile}/>
						</div>
					</li>
				</ul>
			</nav>
			{
				isVisible && (
					<ProfilePopUp ProfileData={ProfileData} ImageProfile={ImageProfile}/>
				)
			}
		</div>
	);
};

export default Header